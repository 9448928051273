* {
  box-sizing: border-box;
}

@media (min-width: 1400px) {
  * {
    font-size: 24px;
  }

}

.App {
  display: grid;
  font-family: Raleway, sans-serif;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto 2fr;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.App::after {
  background-color: #2C2C2C;
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.8rem;
  z-index: 99;
}

@media (max-width: 768px) {
  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  * {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .App::after {
    display: none;
  }
}

@media (max-width: 400px) {
  * {
    font-size: 10px;
  }
}

.SRLImage {
  width: fit-content !important;
}
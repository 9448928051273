.form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    margin-top:2rem;
    padding-left: 4rem;
    gap: .3rem;
}

input, textarea {
    color: #2C2C2C;
    font-family: Raleway, sans-serif;
    font-size: 1rem;
    border: 0;
    margin: 0 0 0 .8rem;
    background-color: #FFEB8B;
    padding: .8rem;
}

input::placeholder, textarea::placeholder {
    color: #6c6c6c;
}

input:focus, textarea:focus {
    outline-color: #F2F2F2;
}

input.name {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
}

input.email {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
}

textarea.message {
    grid-column: 1 / 5;
    grid-row: 3 / 5;
    resize: none;
}

input.phone {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
}

button {
    background-color: #649C08;
    border: 0;
    color: #F2F2F2;
    font-family: Raleway, sans-serif;
    font-size: 1.2rem;
    padding: 0.2rem 0.1rem;
    grid-column: 3 / 5;
    grid-row: 5 / 6;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .form {
        margin-top:2rem;
        padding-left: 0;
    }
}
.gallery {
    display: grid;
    gap: .5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 1rem 0;
    width: 100%;
}

.gallery Img {
    width: 100%;
}

.gallery .--lightbox-only {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -9999;
}

.gallery .gallery-main-photo {
    cursor: pointer;
    position: relative;
    transition: transform 100ms ease-in-out;
}

.gallery .gallery-main-photo:hover {
    transform: scale(1.05);
    z-index: 999;
}

.gallery .gallery-main-photo p {
    background-color: rgba(0,0,0,.8);
    color: #ffffff;
    bottom: 0;
    height: auto;
    margin: 0;
    padding: .8rem;
    position: absolute;
    width: 100%;
    z-index: 99;
}

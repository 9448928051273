.logo-section {
    display: flex;
    background-color: #F2F2F2;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding: 2rem 4rem 0 4rem;
}

.logo-section .logo-img {
    width: 18rem;
}

.logo-section .social-link .social-img {
    padding: .125rem 1rem;
    width: 3.25rem;
}

.logo-section .social-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 768px) {
    .logo-section {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 4rem 2.5rem 4rem;
    }

    .logo-section .logo-img {
        margin-bottom: 1rem;
    }

    .logo-section .social-container {
        flex-direction: row;
    }

    .logo-section .social-link .social-img {
        padding: .2rem .2rem;
        width: 1.8rem;
    }
}
.headline-section {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding: 0 4rem;
}

.headline-section h1 {
    text-transform: uppercase;
    color: #000000;
    font-weight: 400;
    -webkit-text-stroke: 1px #F2F2F2;
    text-shadow: 6px 6px 0px #FFD91F;
}

.headline-section h1 .sm {
    font: inherit;
    font-size: 2.8rem;
}
.headline-section h1 .md {
    font: inherit;
    font-size: 3.2rem;
}
.headline-section h1 .lg {
    font: inherit;
    font-size: 5.8rem;
}

.headline-section strong {
    font-size: inherit;
    font-weight: 700;
}

.headline-section p {
    font-size: 1.25rem;
}

@media (max-width: 768px) {
    .headline-section {
        align-items: center;
        justify-content: center;
        padding: 0 2rem 2rem 2rem;
        text-align: center;
    }

    .headline-section h1 .sm {
        font: inherit;
        font-size: 2.2rem;
    }
    .headline-section h1 .md {
        font: inherit;
        font-size: 2.7rem;
    }
    .headline-section h1 .lg {
        font: inherit;
        font-size: 5rem;
    }
}
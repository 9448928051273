.staticmap {
    position: relative;
}
.staticmap-img {
    width: 80%;
}

.staticmap-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
}

.staticmap-buttons a {
    background-color: #FFD91F;
    color: #2C2C2C;
    font-size: 1.2rem;
    margin-right: .5rem;
    padding: .2rem .4rem;
    text-decoration: none;
    text-transform: uppercase;
}

.staticmap-address {
    text-align: left;
}

@media (max-width: 768px) {
    .staticmap-img {
        width: 100%;
    }

    .staticmap-address {
        text-align: center;
    }

    .staticmap-buttons {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .staticmap-buttons a {
        font-size: 1.1rem;
    }
}
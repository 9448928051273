.whatsappbutton {
    background-color: #649C08;
    border-radius: 4px;
    height: auto;
    padding: 0.6rem 3rem 0.4rem .6rem;
    position: fixed;
    right: -.4rem;
    top: 4rem;
    width: 3rem;
    z-index: 999;
}

@media (max-width: 768px) {
    .whatsappbutton {
        align-items: center;
        display: flex;
        border-radius: 50% 0 50% 50%;
        box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.25);
        height: auto;
        justify-content: center;
        padding: 1rem 1rem;
        position: fixed;
        right: -.1rem;
        top: -.1rem;
        width: auto;
        z-index: 999;
    }
}
.title {
    display: flex;
    flex-direction: column;
}

.title h3 {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 1.5rem 0;
    text-transform: uppercase;
}

.title h3 strong {
    font-size: inherit;
    font-weight: 700;
}

.title .title-underline {
    background: rgb(255,255,255);
    height: .25rem;
    position: relative;
    width: 30rem;
}

.title.--left {
    align-items: flex-start;
}

.title.--right {
    align-items: flex-end;
}

.title.--left h3 {
    text-align: left;
}

.title.--right h3{
    text-align: right;
}

.title.--left .title-underline {
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    left: -4rem;
}

.title.--right .title-underline{
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    right: -4rem;
}

@media (max-width: 768px) {

    .title.--left {
        align-items: center;
    }

    .title.--right {
        align-items: center;
    }

    .title h3 {
        font-size: 2rem;
        margin: 1rem 0;
    }

    .title.--left h3 {
        text-align: center;
    }

    .title.--right h3{
        text-align: center;
    }

    .title .title-underline {
        width: 100%;
    }


    .title.--left .title-underline {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        left: 0;
    }

    .title.--right .title-underline{
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        right: 0;
    }
}